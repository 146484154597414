import React from "react"
import styled from "styled-components"

// Components
import Layout from "../layout/layout"
import SEO from "../layout/seo"

// Styled
import Button from "../components/styled/button"
import { media } from "styled-bootstrap-grid"
import { Section } from "../components/styled/layout"
import { Title, Subtitle } from "../components/styled/typography"

const Wrapper = styled(Section)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  ${media.md`
    height: 60vh;
  `}
`

const StyledTitle = styled(Title)`
  font-size: 100px !important;
  line-height: 100px;
  font-weight: 700;
  margin-bottom: 0.25rem;

  ${media.md`
    font-size: 160px !important;
    line-height: 140px;
  `}

  ${media.xl`
    line-height: 220px;
    font-size: 256px !important;
  `}
`

const StyledSubtitle = styled(Subtitle)`
  margin-top: 0.25rem;
  margin-bottom: 2rem;
`

const ErrorPage = () => (
  <Layout>
    <SEO title="404" />
    <Wrapper>
      <StyledTitle as="h2">404</StyledTitle>
      <StyledSubtitle as="p">Nie ma takiej strony</StyledSubtitle>
      <Button to="/" primary>
        Strona główna
      </Button>
    </Wrapper>
  </Layout>
)

export default ErrorPage
